import React from "react";
import { Helmet } from "react-helmet"
import StandardLayout from "../layout/StandardLayout";

const PageNotFound = () => {
    return <StandardLayout>

        <Helmet>
            <title>404 - Burke Shipping Services</title>
        </Helmet>

        <div className="u-fill-1 u-pad-large u-pad-xlarge@m">
            <div className="l-wrap u-width-3 u-align-center u-marg-bottom u-marg-bottom-large@m">
                <h1 className="c-heading u-font-xxl">404</h1>
                <p className="u-font-xl u-marg-bottom-large">Page not found</p>
                <p className="u-marg-bottom">Sorry, we couldn't find the page you were looking for.</p>
                <p>The page you were looking for can't be found, it might have been moved or even taken down from the site, or you could have typed the wrong address.</p>
            </div>
        </div>



        <div className="c-splitter"></div>
    </StandardLayout>
};

export default PageNotFound;